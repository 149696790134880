<template>
  <div class="sidebar-nav d-flex flex-column align-items-center">
    <div class="close-menu" @click="$parent.showMenu = false"><i class="fa fa-close"></i></div>
    <a href="/" class="logo">
      <img class="img-fluid" src="/assets/dashboard/sm-logo.svg" alt="">
    </a>
    <div @click="$parent.showMenu = false" class="nav-menu d-flex flex-column ">
      <router-link to="/user" class="nav-link d-flex flex-column align-items-center">
        <img class="dashboard-icon" src="/assets/dashboard/dashboard.svg" alt="">
        <img class="dashboard-icon-bg" src="/assets/dashboard/dashboard-bg.svg" alt="">
        <span>Dashboard</span>
      </router-link>
      <router-link to="/user/application" class="nav-link d-flex flex-column align-items-center">
        <img class="dashboard-icon" src="/assets/dashboard/application.svg" alt="">
        <img class="dashboard-icon-bg" src="/assets/dashboard/application-bg.svg" alt="">
        <span>Application</span>
      </router-link>
      <router-link to="/user/examination" class="nav-link d-flex flex-column align-items-center">
        <img class="dashboard-icon" src="/assets/dashboard/examination.svg" alt="">
        <img class="dashboard-icon-bg" src="/assets/dashboard/examination-bg.svg" alt="">
        <span>Examination</span>
      </router-link>
    </div>
    <div class="logout" id="logout">
      <!--      <router-link to="/user/help" class="nav-link d-flex flex-column align-items-center ">-->
      <!--        <img class="dashboard-icon" src="/assets/dashboard/help.svg" alt="">-->
      <!--        <img class="dashboard-icon-bg" src="/assets/dashboard/help-bg2.svg" alt="">-->
      <!--        <span>Help</span>-->
      <!--      </router-link>-->
      <router-link to="/user/settings" class="nav-link d-flex flex-column align-items-center ">
        <img class="dashboard-icon" src="/assets/dashboard/settings.svg" alt="">
        <img class="dashboard-icon-bg" src="/assets/dashboard/settings-bg.svg" alt="">
        <span>Settings</span>
      </router-link>
      <a href="javascript:void(0)" @click.prevent="logout"
         class="nav-link d-flex flex-column align-items-center logout">
        <img class="dashboard-icon" src="/assets/dashboard/log-out.svg" alt="">
        <img class="dashboard-icon-bg" src="/assets/dashboard/log-out.svg" alt="">
        <span>Log out</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideNav",
  methods: {
    logout() {
      this.$store.dispatch('clearState')
      // localStorage.clear()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>