<template>
  <div>
    <div class="d-flex dashboard-layout w-100">
      <div class="navbar-content" :class="showMenu===true ? 'show-menu' : ''">
        <side-nav/>
      </div>
      <div class="dashboard-content">
        <div class="top-bar d-flex justify-content-between">

          <h2><span class="open-menu" @click="showMenu=true"><i class="fa fa-bars"></i></span>{{ $route.meta.title }}
          </h2>
          <div class="avatar" @click="activeMenu = !activeMenu">
            <img class="img-fluid"
                 :src="activeUser.avatar ? activeUser.avatar : 'https://365psd.com/images/istock/previews/1009/100996291-male-avatar-profile-picture-vector.jpg'"
                 alt="">
            <span><img src="/assets/dashboard/down-arrow.svg" alt=""></span>

            <div class="dropdown" v-if="activeMenu===true">
              <router-link to="/user/settings" class="d-flex">
                <img src="/assets/dashboard/user2.svg" alt=""> Profile
              </router-link>
              <a href="javascript:void(0)" @click.prevent="logout" class="d-flex logout">
                <img src="/assets/dashboard/log-out.svg" alt=""> Log out
              </a>
            </div>
          </div>
        </div>
        <router-view/>
      </div>
    </div>
  </div>

</template>

<script>

import SideNav from "./inc/SideNav";

export default {
  name: "Layout",
  components: {SideNav},
  data: () => ({
    activeMenu: false,
    showMenu: false,
    activeUser: {}
  }),
  methods: {
    logout() {
      this.$store.dispatch('clearState')
      this.$router.push('/')
    }
  },
  mounted() {
    this.activeUser = this.$store.state.activeUser
  }
}
</script>

<style scoped>

</style>